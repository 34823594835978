import classnames from 'classnames';
import {Modal} from 'components/Modal';
import {Messenger} from 'managers/messenger';
import Markdown from 'markdown-to-jsx';
import {func, string} from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {Trans} from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import {generalSelector} from 'selectors';
import './_Styles.scss';

const propTypes = {
  content: string.isRequired,
  onImageClick: func,
};

const defaultProps = {
  onImageClick: null,
};

export const MarkdownContent = (props) => {
  return <MarkdownRender {...props} />;
};

MarkdownContent.propTypes = propTypes;
MarkdownContent.defaultProps = defaultProps;

const MarkdownRender = React.memo(
  ({
    content,
    enableReadMore,
    enableTruncate = false,
    onImageClick,
    disableImageInteractions = false,
    onClickReadMore = () => {},
  }) => {
    const [imageFullscreen, setImageFullscreen] = useState(null);
    const [shouldShowReadMore, setShouldShowReadMore] = useState(false);
    const widgetMode = generalSelector.getWidgetMode();

    const sanitizedContent = sanitizeHtml(content, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        'img',
        'iframe',
        'ins',
        'del',
      ]),
      allowedIframeHostnames: [
        'www.youtube.com',
        'player.vimeo.com',
        'www.loom.com',
      ],
      allowedAttributes: {
        iframe: ['src', 'title', 'allowfullscreen'],
        img: ['src', 'alt'],
        a: ['href', 'target'],
      },
    });

    const refMd = useRef(null);

    useEffect(() => {
      if (refMd.current == null) {
        return;
      }
      if (
        refMd.current.scrollHeight > refMd.current.clientHeight &&
        enableReadMore === true
      ) {
        setShouldShowReadMore(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refMd.current]);
    return (
      <div
        className={classnames('md-renderer-wrapper', {
          'has-read-more': shouldShowReadMore,
          'has-truncate': enableTruncate,
        })}
        ref={refMd}>
        <div className="md-renderer">
          <Markdown
            linkTarget="_blank"
            options={{
              overrides: {
                img: {
                  component: ({src, alt}) => {
                    return (
                      <div
                        className={classnames('img-wrapper', {
                          'has-interactions-disabled':
                            disableImageInteractions === true,
                        })}
                        onClick={() => {
                          if (disableImageInteractions === false) {
                            if (onImageClick != null) {
                              return onImageClick(src);
                            }
                            return widgetMode === false
                              ? setImageFullscreen({src, alt})
                              : Messenger.sendOpenModalImage(src);
                          }
                        }}>
                        <img src={src} alt={alt} />
                        {disableImageInteractions === false && (
                          <i className="icon-search"></i>
                        )}
                      </div>
                    );
                  },
                },
              },
              createElement: (type, props, children) => {
                if (type === 'iframe') {
                  return (
                    <div className="iframe-wrapper">
                      <iframe
                        src={props.src}
                        title={'jimo-embedded-video'}
                        allowFullScreen
                      />
                    </div>
                  );
                }
                return <>{React.createElement(type, props, children)}</>;
              },
            }}>
            {sanitizedContent}
          </Markdown>
        </div>
        <ModalImageFullscreen
          isOpen={imageFullscreen != null && widgetMode === false}
          onRequestClose={() => setImageFullscreen(null)}>
          {imageFullscreen != null && (
            <img src={imageFullscreen.src} alt={imageFullscreen.alt} />
          )}
        </ModalImageFullscreen>
        {shouldShowReadMore === true && (
          <div className="md-read-more" onClick={onClickReadMore}>
            <Trans i18nKey="common.readMore"></Trans>
          </div>
        )}
      </div>
    );
  }
);

MarkdownRender.propTypes = propTypes;
MarkdownRender.defaultProps = defaultProps;

export const ModalImageFullscreen = ({className, ...props}) => {
  const classNames = classnames('modal-image-fullscreen', className);

  return (
    <Modal
      animationClass="fade-in fast"
      className={classNames}
      {...props}></Modal>
  );
};
