import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import React, {useContext, useEffect} from 'react';
import sanitizeHtml from 'sanitize-html';
import {BLOCK_BODY} from 'shared/front/components/Poke/constants/blocks';
import {PostContext} from '../../context';
import './_Styles.scss';

const PostBlockBody = ({isCollapsed}) => {
  const {
    post,
    theme,
    language,
    onUrlClick = () => {},
    onImageClick = () => {},
    AnalyticViewTrigger = null,
    addFontFamily,
  } = useContext(PostContext);

  const step = post.steps[0] || {};
  const block = step?.blocks.find((b) => b.type === BLOCK_BODY);

  const translation = block.translations?.find((t) => t.language === language);

  const sanitizedContent = sanitizeHtml(
    translation != null ? translation.value : block.value,
    {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        'img',
        'iframe',
        'ins',
        'del',
      ]),
      allowedIframeHostnames: [
        'www.youtube.com',
        'player.vimeo.com',
        'www.loom.com',
      ],
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        a: ['href', 'target'],
        iframe: [
          'src',
          'width',
          'height',
          'frameborder',
          'allowfullscreen',
          'style',
        ],
        img: ['src', 'width', 'height', 'alt', 'style'],
      },
      transformTags: {
        s: sanitizeHtml.simpleTransform('del'),
      },
    }
  );

  const {text} = theme ?? {};
  const {bodyFontColor, bodyFontFamily = 'Inter'} = text ?? {};

  useEffect(() => {
    addFontFamily(bodyFontFamily);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyFontFamily]);

  const ATagtoDiv = ({children, ...props}) => {
    const {href} = props;
    return (
      <span className="a-div" onClick={() => onUrlClick(href)}>
        {children}
      </span>
    );
  };

  return (
    <div
      className={classnames('post-block-body', {
        'is-collapsed': isCollapsed,
      })}
      style={{
        ...(bodyFontColor != null
          ? {
              color: bodyFontColor,
            }
          : {}),
        ...(bodyFontFamily != null
          ? {
              fontFamily: `${bodyFontFamily}, ui-sans-serif, system-ui, sans-serif`,
            }
          : {}),
      }}>
      <div className="body-markdown">
        <Markdown
          linkTarget="_blank"
          options={{
            overrides: {
              a: {
                component: ATagtoDiv,
              },
              img: {
                component: ({src, alt}) => {
                  return (
                    <div
                      className={classnames('img-wrapper')}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onImageClick(src);
                      }}>
                      <img src={src} alt={alt} />

                      <i className="icon-search"></i>
                    </div>
                  );
                },
              },
            },
            createElement: (type, props, children) => {
              if (type === 'iframe') {
                return (
                  <div className="iframe-wrapper">
                    <iframe
                      src={props.src}
                      title={'jimo-embedded-video'}
                      allowFullScreen
                    />
                  </div>
                );
              }
              return <>{React.createElement(type, props, children)}</>;
            },
          }}>
          {sanitizedContent}
        </Markdown>
      </div>
      {/* Analytics */}
      {AnalyticViewTrigger != null && <AnalyticViewTrigger />}
    </div>
  );
};

export default PostBlockBody;
